





































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import './scss/PopupEditJuristicPerson.scss';
import { IMemberRole, IProject, IProjectState } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { AboutProjectActions } from '@store/modules/about-project/Types';

const aboutProjectNamespace = namespace('storeAboutProject');
const NSProject = namespace('storeProject');

@Component({
    name: 'PopupEditJuristicPerson',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    }
})

export default class PopupEditJuristicPerson extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        innField: HTMLFormElement,
    }

    @Prop({}) selectedItem!: any;

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.State((state: IProjectState) => state.memberRolesJuristic) memberRolesJuristic!: IMemberRole[];

    @aboutProjectNamespace.Action(AboutProjectActions.A_EDIT_JURISTIC_PERSON)
    editJuristicPerson!: (payload) => Promise<void>;
    btnDisabled = false;
    loading = false;

    fields = {
        fullTitle: '',
        title: '',
        inn: '',
        role: '',
        startDate: '',
        endDate: '',
    };

    innLength: number = 12;

    get projectId() {
        return this.projectData ? this.projectData.id.toString() : '';
    }

    get titleForSelectRole() {
        if (this.fields.role) {
            const roleItem = this.memberRolesJuristic.filter(item => item.uuid === this.fields.role);
            if (roleItem.length) {
                return roleItem[0].value;
            }
        }
        return 'Выберите роль';
    }

    @Watch('selectedItem')
    onChangeSelectedItem() {
        this.resetFields();
    }

    @Watch('fields.inn')
    watchFieldInn() {
        if (this.fields.inn.length > this.innLength) {
            let output = '';
            for (let i = 0; i < this.innLength; i++) {
                output += this.fields.inn[i];
            }
            this.$refs.innField.$el.querySelector('input').value = output;
            this.fields.inn = output;
        }
    }

    closeAndReset() {
        this.closePopup();
        this.resetFields();
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        this.loading = false;
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    onSuccess() {
        this.btnDisabled = true;
        this.sendForm();
        this.btnDisabled = false;
    }

    sendForm() {
        const changedItem = JSON.parse(JSON.stringify(this.selectedItem));
        const data: any = {
            appId: this.projectId,
            kontragentId: this.selectedItem.id,
            roleId: this.selectedItem.role ? this.selectedItem.role.id : '',
            name: '',
            fullName: '',
            inn: '',
        };

        if (this.fields.title) {
            data.name = this.fields.title;
            changedItem.name = this.fields.title;
        }
        if (this.fields.fullTitle) {
            data.fullName = this.fields.fullTitle;
            changedItem.fullName = this.fields.fullTitle;
        }
        if (this.fields.inn) {
            data.inn = this.fields.inn;
            changedItem.inn = this.fields.inn;
        }
        if (this.fields.role) {
            data.roleId = this.fields.role;
            changedItem.role = this.memberRolesJuristic
                .filter(item => item.uuid === this.fields.role)
                .map(role => ({id: role.uuid, name: role.label}))[0];
        }
        if (this.fields.startDate) {
            data.startDate = this.fields.startDate;
            changedItem.startDate = this.fields.startDate;
        }
        if (this.fields.endDate) {
            data.endDate = this.fields.endDate;
            changedItem.endDate = this.fields.endDate;
        }

        this.editJuristicPerson(data)
            .then(() => {
                this.$emit('confirm-edit-juristic-person');
                this.loading = true;
        });
    }

    selectRole(e) {
        this.fields.role = e.uuid;
    }

    resetFields() {
        this.fields = {
            fullTitle: this.selectedItem?.fullName || '',
            title: this.selectedItem?.name || '',
            inn: this.selectedItem?.inn || '',
            role: this.selectedItem?.role?.id || '',
            startDate: this.selectedItem?.startDate?.split('T')[0] || '',
            endDate: this.selectedItem?.endDate?.split('T')[0] || '',
        }
    }
}
